<!--
 * @Description: 设备类型统计
 * @Author: kecraft
 * @Date: 2024-05-11 09:50:21
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-11 14:20:51
 * @FilePath: /impact-iotos-console/src/views/dashboard/components/deviceTypeStat.vue
-->
<template>
  <div class="device-stat">
    <div id="deviceTypeStatChart"></div>
    <div class="legend-container">
      <div v-for="(item, index) of showDate" :key="index" class="legend-item">
        <div class="icon-box" :style="{ background: colors[index] }"></div>
        <div class="legend-title">{{ item.name }}</div>
        <div class="legned-value">{{ item.value }}<span class="unit">个</span></div>
      </div>
    </div>
  </div>
</template>

<script setup>
let myChart = null;
import api from "@/api/api";
import * as echarts from "echarts";
import { onMounted, onBeforeUnmount, ref } from 'vue';
const colors = ["#5796D6", "#FF9A5C", "#6367DC", "#68C7B8"]
const total = ref(0)
const showDate = ref([
  { value: 0, name: '网关' },
  { value: 0, name: '中继' },
  { value: 0, name: '终端子设备' },
  { value: 0, name: '直连设备' },
])
onMounted(() => {
  api.countDeviceByDeviceType().then(({ code, res }) => {
    if (code == "0") {
      const { gatewayCount, generalCount, switchCount, terminalCount } = res.data;
      showDate.value[0].value = gatewayCount || 0;
      showDate.value[1].value = switchCount || 0;
      showDate.value[2].value = terminalCount || 0;
      showDate.value[3].value = generalCount || 0;
      let totalDevice = 0
      showDate.value.forEach(item => {
        totalDevice += item.value
      })
      total.value = totalDevice || 0;
    }
    initChart()
  });

})
const initChart = () => {
  if (!myChart) {
    myChart = echarts.init(document.getElementById('deviceTypeStatChart'));
  }
  const option = {
    color: ["#5796D6", "#FF9A5C", "#6367DC", "#68C7B8"],
    tooltip: {
      trigger: 'item'
    },
    title: [{
      text: '总数',
      x: 'center',
      top: '40%',
      textStyle: {
        color: '#333333',
        fontSize: 14,
        fontWeight: '600',
      }
    }, {
      text: total.value,
      x: 'center',
      top: '50%',
      textStyle: {
        fontSize: 16,
        color: '#333333',
        foontWeight: '600',
      },
    }],
    legend: {
      show: false,
    },
    series: [
      {
        z: 0,
        type: 'pie',
        center: ['50%', '50%'],
        radius: ['35%', '65%'],
        hoverAnimation: false,
        itemStyle: {
          normal: {
            color: "#EDF8FF",
          }
        },
        silent: true,
        data: [100]
      },
      {

        name: '设备数量',
        type: 'pie',
        center: ['50%', '50%'],
        radius: ['40%', '60%'],
        hoverAnimation: false,
        itemStyle: {
          normal: {
            borderColor: "#EDF8FF",
            borderWidth: 6
          }
        },
        label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          show: false
        },
        data: showDate.value
      },

    ]
  };
  myChart.setOption(option);
  window.addEventListener("resize", function () {
    myChart.resize();
  });
}
onBeforeUnmount(() => {
  if (myChart) {
    myChart.dispose();
  }
})
</script>

<style lang="less" scoped>
.device-stat {
  width: 100%;
  height: 270px;
  display: flex;
  align-items: center;

  .legend-item:last-child {
    margin-bottom: 0;
  }

  .legend-item {
    margin-left: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .icon-box {
      width: 10px;
      height: 10px;
      margin-right: 16px;
    }

    .legend-title {
      font-size: 14px;
      color: #333333;
      font-weight: 600;
      line-height: 20px;
      width: 100px;
    }

    .legned-value {
      font-size: 24px;
      color: #333333;
      font-weight: 600;
      line-height: 24px;

      span {
        font-size: 12px;
        margin-left: 5px;
      }
    }
  }

  #deviceTypeStatChart {
    width: 50%;
    height: 100%;
  }
}
</style>