<!--
 * @Description: 消息量统计趋势
 * @Author: kecraft
 * @Date: 2024-05-11 10:49:09
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-16 09:43:38
 * @FilePath: /impact-iotos-console/src/views/dashboard/components/packageTrend.vue
-->
<template>
  <div class="device-trend">
    <div class="btns-container">
      <span :class="isSelect == 'HOURS' ? 'isSelect' : ''" @click="handleSelect('HOURS')">小时</span>
      <span :class="isSelect == 'DAY' ? 'isSelect' : ''" @click="handleSelect('DAY')">天</span>
      <span :class="isSelect == 'MONTH' ? 'isSelect' : ''" @click="handleSelect('MONTH')">月</span>
    </div>
    <div id="packageTrendChart"></div>
  </div>
</template>

<script setup>
let myChart = null;
import api from "@/api/api"
import * as echarts from "echarts";
import { onMounted, onBeforeUnmount, ref } from 'vue';
const isSelect = ref("");
const start = ref(0);
const end = ref(0);
const packageList = ref([]);
const xAxisList = ref([]);
const handleSelect = (v) => {
  if (isSelect.value === v) return;
  isSelect.value = v;
  if (isSelect.value === 'HOURS') {
    // 小时
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    start.value = Date.parse(currentDate)
    end.value = Date.parse(new Date())
  } else if (isSelect.value === 'DAY') {
    // 天
    let currentDate1 = new Date();
    currentDate1.setMonth(currentDate1.getMonth() - 1);
    start.value = Date.parse(currentDate1)
    end.value = Date.parse(new Date())
  } else if (isSelect.value === 'MONTH') {
    // 月
    let currentDate2 = new Date();
    currentDate2.setFullYear(currentDate2.getFullYear() - 1);
    start.value = Date.parse(currentDate2)
    end.value = Date.parse(new Date())
  }
  getDate()
}
onMounted(() => {
  handleSelect("HOURS")
})

const getDate = () => {
  api.countPackageNumber({
    startTime: start.value,
    endTime: end.value,
    type: isSelect.value,
  }).then(({ code, res }) => {
    if (code === "0") {
      const list = res.data;
      const xAxis = []
      const list1 = []
      list.forEach(item => {
        // const dataList = item.date.split(" ")
        // const showDate = dataList[dataList.length - 1]
        const showDate = item.date;
        xAxis.push(showDate);
        list1.push(item.sum)
      })
      packageList.value = list1;
      xAxisList.value = xAxis;
    } else {
      packageList.value = [];
      xAxisList.value = [];
    }
    initChart()
  })
}
const initChart = () => {
  if (!myChart) {
    myChart = echarts.init(document.getElementById('packageTrendChart'));
  }
  const option = {
    color: ["#367CC5"],
    tooltip: {
      trigger: "axis",
      show: true,
    },
    legend: {
      right: '2%',
      data: ['设备指令发送量',],
      // itemWidth
    },
    grid: {
      top: '15%',
      left: '5%',
      right: '2%',
      bottom: '15%',
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisList.value,
      axisTick: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#EAEAEA'
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: '#333333',
        },
      },
    },
    yAxis: [
      {
        name: '单位：个',
        nameTextStyle: {
          color: '#333333',
          align: "right",
        },
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#EAEAEA'
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#EAEAEA'
          }
        },
        axisLabel: {
          show: true,
          textStyle: {
            color: '#333333',
          },
        },
      },
      {
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#EAEAEA'
          }
        },
        axisLabel: {
          show: false,
          textStyle: {
            color: '#333333',
          },
        },
      },
    ],
    series: [
      {
        name: '设备指令发送量',
        data: packageList.value,
        type: 'line',
        symbolSize: 7,
        lineStyle: {
          width: 2,
          color: "#367CC5",
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            {
              offset: 1,
              color: "rgba(68, 146, 229, 0.30)", // 0% 处的颜色
            },
            {
              offset: 0,
              color: "rgba(102, 179, 228, 0)", // 100% 处的颜色
            },
          ]),
        },
      },
    ]
  };
  myChart.setOption(option);
  window.addEventListener("resize", function () {
    myChart.resize();
  });
}
onBeforeUnmount(() => {
  if (myChart) {
    myChart.dispose();
  }
})
</script>

<style lang="less" scoped>
.device-trend {
  width: 100%;
  height: 270px;
  position: relative;

  #packageTrendChart {
    width: 100%;
    height: 100%;
  }

  .btns-container {
    position: absolute;
    right: 30px;
    top: -30px;

    &>span {
      cursor: pointer;
      margin-left: 15px;
      font-size: 12px;
      line-height: 17px;
      // font-weight: lighter;
      color: #333333;
      border-bottom: 2px solid transparent;
    }

    .isSelect {
      color: #367CC5;
      border-bottom: 2px solid #367CC5;
    }
  }
}
</style>