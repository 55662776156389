<!--
 * @Description: 看板-标题
 * @Author: kecraft
 * @Date: 2024-05-11 09:36:17
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-11 09:40:36
 * @FilePath: /impact-iotos-console/src/views/dashboard/components/dashboardTitle.vue
-->
<template>
  <div class="dashboard-title">
    <div class="title-left"></div>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      bigtitle: ""
    }
  },
  mounted() {
    this.gettitle()
  },
  methods: {
    gettitle() {
      if (this.title) {
        this.bigtitle = this.title
        return
      }
    }
  }
}
</script>

<style lang="less" scoped>
.dashboard-title {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 20px;

  .title-left {
    width: 3px;
    height: 17px;
    background: #367CC5;
    margin-right: 7px;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: #367CC5;
  }
}
</style>