<!--
 * @Description: 看板
 * @Author: kecraft
 * @Date: 2024-05-11 09:06:58
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-11 14:15:29
 * @FilePath: /impact-iotos-console/src/views/dashboard/index.vue
-->
<template>
  <div class="dashboard">
    <div class="top-container">
      <div class="top-left">
        <div class="product-box">
          <div class="box-title">产品数量</div>
          <div class="box-number" style="color:#DEA830">{{ product }}</div>
        </div>
        <div class="device-box">
          <div class="box-title">设备数量</div>
          <div class="box-number" style="color:#3F8AE0">{{ device }}</div>
        </div>
      </div>
      <div class="top-middle">
        <dashboardTitle title="设备状态统计" />
        <deviceStat />
      </div>
      <div class="top-right">
        <dashboardTitle title="设备类型统计" />
        <deviceTypeStat />
      </div>
    </div>
    <div class="bottom-container">
      <dashboardTitle title="设备统计趋势" />
      <deviceTrend />
    </div>
    <div class="bottom-container">
      <dashboardTitle title="消息量趋势" />
      <packageTrend />
    </div>
  </div>
</template>

<script setup>
import dashboardTitle from './components/dashboardTitle';
import deviceStat from "./components/deviceStat";
import deviceTypeStat from "./components/deviceTypeStat";
import deviceTrend from './components/deviceTrend.vue';
import packageTrend from './components/packageTrend.vue';
import api from "@/api/api";
import { onMounted, ref } from 'vue';
const device = ref(0);
const product = ref(0)
onMounted(() => {
  api.countTotal().then(({ code, res }) => {
    if (code == "0") {
      const { deviceAll, productAll } = res.data;
      device.value = deviceAll;
      product.value = productAll;
    }
  });
})
</script>

<style lang="less" scoped>
.dashboard {
  background: #FAFAFB;
  box-sizing: border-box;
  padding: 15px 12px;
}

.top-container {
  height: 329px;
  display: flex;
  justify-content: space-between;

  .top-left {
    width: 332px;

    &>div {
      height: 157px;
    }

    .product-box {
      background: url("~@/assets/dashborad/product.png") no-repeat;
      background-size: 100% 100%;
    }

    .device-box {
      background: url("~@/assets/dashborad/device.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 15px;
    }

    .box-title {
      box-sizing: border-box;
      padding-top: 24px;
      padding-left: 44px;
      font-size: 24px;
      line-height: 33px;
      font-weight: 600;
      color: #333333;
    }

    .box-number {
      margin-top: 15px;
      box-sizing: border-box;
      padding-left: 44px;
      font-size: 50px;
      line-height: 61px;
      font-weight: bold;
    }
  }

  .top-middle,
  .top-right {
    width: 617px;
    background: #ffffff;
  }
}

.bottom-container {
  width: 100%;
  height: 314px;
  background: #ffffff;
  margin-top: 15px;
}
</style>