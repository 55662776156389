<!--
 * @Description: 设备状态统计
 * @Author: kecraft
 * @Date: 2024-05-11 09:50:21
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-11 17:31:57
 * @FilePath: /impact-iotos-console/src/views/dashboard/components/deviceStat.vue
-->
<template>
  <div class="device-stat">
    <div class="progress-box" v-for="(item, index) of dataList" :key="index">
      <div class="progress-item">
        <el-progress type="circle" :percentage="item.percentage * 1" :stroke-width="10" :color="item.color"
          class="progresss">
          <template #default>
            <div class="percentage-label">{{ item.name }}</div>
            <div class="percentage-value">{{ item.percentage }}<span class="percentage-unit">%</span></div>
          </template>
        </el-progress>
      </div>
      <div class="legend-box">
        <div class="legend-icon" :style="{ background: item.color }"></div>
        <div class="legend-title">
          <div class="big-title">{{ item.label }}</div>
          <div class="legned-value">{{ item.value }}<span class="unit">个</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import api from "@/api/api";
const dataList = ref([
  {
    color: "#28B0EE",
    label: "激活设备",
    name: "激活率",
    value: 0,
    percentage: 0
  },
  {
    color: "#68C7B8",
    label: "在线设备",
    name: "在线率",
    value: 0,
    percentage: 0
  },
  {
    color: "#959595",
    label: "离线设备",
    name: "离线率",
    value: 0,
    percentage: 0
  }
])
onMounted(() => {
  api.getCountTotalDevice().then(res => {
    if (res.code === "0") {
      const { deviceActivated, deviceAll, deviceOnline } = res.res.data;
      dataList.value[0].value = deviceActivated || 0
      dataList.value[1].value = deviceOnline || 0
      dataList.value[2].value = (deviceAll - deviceOnline) || 0
      if (deviceAll && deviceAll > 0) {
        dataList.value[0].percentage = ((deviceActivated / deviceAll) * 100).toFixed(2) * 1 || "0"
        dataList.value[1].percentage = ((deviceOnline / deviceAll) * 100).toFixed(2) * 1 || "0"
        dataList.value[2].percentage = (((deviceAll - deviceOnline) / deviceAll) * 100).toFixed(2) * 1 || "0"
      }
    }
  })
})
</script>

<style lang="less" scoped>
.device-stat {
  width: 100%;
  height: 270px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;

  .progress-box {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .progress-box:nth-child(2) {
    box-sizing: border-box;
    // padding: 0 58px;
  }

  .legend-box {
    display: flex;
    align-items: center;
    margin-top: 40px;

    .legend-icon {
      width: 10px;
      height: 10px;
      background: #f0f;
      margin-right: 9px;

    }

    .big-title {
      font-size: 14px;
      font-weight: 600;
      color: #333333;
    }

    .legned-value {
      margin-top: 7px;
      font-size: 20px;
      font-weight: bold;
      line-height: 20px;
      color: #333333;

      .unit {
        font-size: 12px;
      }
    }
  }

}

.percentage-label {
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  font-weight: 600;
}

.percentage-value {
  color: #333333;
  font-size: 24px;
  line-height: 33px;
  font-weight: 600;
}

.percentage-unit {
  font-size: 12px;
}
</style>