<!--
 * @Description: 设备统计趋势
 * @Author: kecraft
 * @Date: 2024-05-11 10:49:09
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-11 14:36:55
 * @FilePath: /impact-iotos-console/src/views/dashboard/components/deviceTrend.vue
-->
<template>
  <div class="device-trend" id="deviceTrendChart"></div>
</template>

<script setup>
let myChart = null;
import * as echarts from "echarts";
import api from "@/api/api";
import { onMounted, onBeforeUnmount, ref } from 'vue';
const allList = ref([]);
const offlineList = ref([]);
const onlineList = ref([]);
const xAxisList = ref([]);
onMounted(() => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);
  const start = Date.parse(currentDate)
  const end = Date.parse(new Date())
  api.deviceStatDashboard({
    startTime: start,
    endTime: end
  }).then(({ code, res }) => {
    if (code == "0") {
      const { all, offline, online } = res.data;
      const All = []
      const Offline = []
      const Online = []
      const xAxis = []
      all.forEach((item, index) => {
        All.push(item.count)
        Offline[index] = offline[index].count
        Online[index] = online[index].count
        const formattedDate = timestampToMonthDay(item.countDate);
        xAxis.push(formattedDate)
      })
      xAxisList.value = xAxis
      allList.value = All
      offlineList.value = Offline
      onlineList.value = Online
      initChart()
    }
  })
})
const timestampToMonthDay = (timestamp) => {
  var date = new Date(timestamp);
  var month = date.getMonth() + 1; // getMonth() 返回的月份是从 0 开始的
  var day = date.getDate();
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;
  return month + '/' + day;
}
const initChart = () => {
  if (!myChart) {
    myChart = echarts.init(document.getElementById('deviceTrendChart'));
  }
  const option = {
    color: ["#F3A82A", "#5BCBB7", "#959595"],
    tooltip: {
      trigger: "axis",
      show: true,
    },
    legend: {
      right: '2%',
      data: ['总量', '在线', "离线"],
      // itemWidth
    },
    grid: {
      top: '15%',
      left: '5%',
      right: '2%',
      bottom: '15%',
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisList.value,
      axisTick: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#EAEAEA'
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: '#333333',
        },
      },
    },
    yAxis: [
      {
        name: '单位：个',
        nameTextStyle: {
          color: '#333333',
          align: "right",
        },
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#EAEAEA'
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#EAEAEA'
          }
        },
        axisLabel: {
          show: true,
          textStyle: {
            color: '#333333',
          },
        },
      },
      {
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#EAEAEA'
          }
        },
        axisLabel: {
          show: false,
          textStyle: {
            color: '#333333',
          },
        },
      },
    ],
    series: [
      {
        name: '总量',
        data: allList.value ,
        type: 'line',
        symbolSize: 7,
        lineStyle: {
          width: 2,
          color: "#F3A82A",
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            {
              offset: 1,
              color: "rgba(243, 167, 41, 0.30)", // 0% 处的颜色
            },
            {
              offset: 0,
              color: "rgba(250, 211, 83, 0)", // 100% 处的颜色
            },
          ]),
        },
      },
      {
        name: '在线',
        data: onlineList.value,
        type: 'line',
        symbolSize: 7,
        lineStyle: {
          width: 2,
          color: "#5BCBB7",
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            {
              offset: 1,
              color: "rgba(11, 222, 161, 0.30)", // 0% 处的颜色
            },
            {
              offset: 0,
              color: "rgba(26, 241, 207, 0)", // 100% 处的颜色
            },
          ]),
        },
      },
      {
        name: '离线',
        data: offlineList.value,
        type: 'line',
        symbolSize: 7,
        lineStyle: {
          width: 2,
          color: "#959595",
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            {
              offset: 1,
              color: "rgba(134, 134, 134, 0.30)", // 0% 处的颜色
            },
            {
              offset: 0,
              color: "rgba(168, 168, 168, 0)", // 100% 处的颜色
            },
          ]),
        },
      }
    ]
  };
  myChart.setOption(option);
  window.addEventListener("resize", function () {
    myChart.resize();
  });
}
onBeforeUnmount(() => {
  if (myChart) {
    myChart.dispose();
  }
})
</script>

<style lang="less" scoped>
.device-trend {
  width: 100%;
  height: 270px;
}
</style>